import React, { useEffect, useState } from 'react';

interface PropTypes {
  isArabic: boolean;
}

export default function FAQS({ isArabic }: PropTypes) {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1160);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [faqs, setFaqs] = useState([
    {
      questionEn: 'What is BEANZ?',
      answerEn: 'BEANZ is a mobile app Simplifies Coffee Shop Ordering For In-Store, Curbside Or Dine-In, And Enhances Customer Retention With Customized Rewards.',
      questionAr: '؟BEANZ ما هو',
      answerAr: ' بينز هو تطبيق يبسط طلب المقهى داخل المتجر ، على جانب الطريق أو تناول الطعام في الداخل ، ويعزز الاحتفاظ بالعملاء حسب الطلب المكافآت',
      isOpen: false,
    },
    {
      questionEn: 'Is BEANZ a food delivery app?',
      answerEn: 'No. BEANZ is not a food delivery app. It’s an app that simplifies pickup and dine-in orders for cafes, and offers a comprehensive loyalty solutions for users and coffee shops.',
      questionAr: 'هو تطبيق توصيل الطعام؟ BEANZ هل',
      answerAr: 'لا، ليس تطبيقًا لتوصيل الطعام. إنه تطبيق يعمل على تبسيط طلبات الاستلام وتناول الطعام للمقاهي، ويقدم حلول ولاء شاملة للمستخدمين والمقاهي',
      isOpen: false,
    },
    {
      questionEn: 'How Do I register my store?',
      answerEn: 'Join BEANZ by filling in the form Become a partner above and we will contact you within 24 hours.',
      questionAr: 'BEANZ كيف يمكنني أن أصبح أحد شركاء ',
      answerAr: 'عن طريق ملء النموذج أعلاه وسنتصل بك خلال 24 ساعة',
      isOpen: false,
    },
    {
      questionEn: 'Is Payment done on BEANZ for orders?',
      answerEn: 'Payment is done in-app through Apple Pay, Debit/Credit Card, and in-app wallet top up',
      questionAr: ' للطلبات؟ BEANZ هل يتم الدفع على ',
      answerAr: `Apple Pay يتم الدفع داخل التطبيق من خلال `,
      answerAr2: 'وبطاقة الخصم/الائتمان وتعبئة المحفظة داخل التطبيق',
      isOpen: false,
    },
  ]);

  const toggleFAQ = (index: number) => {
    const updatedFaqs = faqs.map((faq, i) => {
      if (i === index) {
        return { ...faq, isOpen: !faq.isOpen };
      }
      return faq;
    });
    setFaqs(updatedFaqs);
  };

  return (
    <div className={`pt-20 pb-8 flex bg-[#EEF7FA]  flex-container ${isArabic ? 'flex-container-arabic' : 'flex-center'}`}
      style={{
        padding: isMobile ? '2rem' : '5rem',
        paddingRight: isArabic ? (isMobile ? '14rem' : '5.5rem') : isMobile ? '14rem' : '5.5rem',
        paddingLeft: isArabic ? (isMobile ? '14rem' : '5.5rem') : isMobile ? '14rem' : '5.5rem',
      }}>
      <div className={`${isMobile ? 'w-full' : 'w-[70%] wid'}`}
        style={{
          paddingBottom: isArabic ? (isMobile ? '0.8rem' : '0') : isMobile ? '0.8rem' : '0',
          paddingRight: isArabic ? (isMobile ? '0' : '0') : isMobile ? '0' : '0rem',
          paddingLeft: isArabic ? (isMobile ? '0' : '0') : isMobile ? '0' : '0',
          textAlign: isArabic ? 'right' : 'left',
        }}>
        <h2 className={` text-[#00121F] ${isMobile ? 'mb-0' : 'mb-2'} ${isArabic ? 'text-right-arabic' : ''}`}
          style={{
            fontSize: isArabic ? (isMobile ? '15px' : '20px') : isMobile ? '15px' : '20px',
            fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
          }}>
          {isArabic ? 'دعم' : 'Support'}
        </h2>
        <h2 className={`text-[#335A79] ${isArabic ? 'text-right-arabic' : ''}`}
          style={{
            fontSize: isArabic ? (isMobile ? '26px' : '45px') : isMobile ? '26px' : '45px',
            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EndBold' : 'EngBold',
            marginBottom: isMobile ? '0.5rem' : '1.5rem',
          }}>
          {isArabic ? 'أسئلة وأجوبة' : 'FAQS'}
        </h2>
        <p className={`mb-2 text-[#00121F] ${isArabic ? 'text-right-arabic' : ''}`}
          style={{
            fontSize: isArabic ? (isMobile ? '12px' : '20px') : isMobile ? '12px' : '20px',
            lineHeight: isMobile ? '1.5rem' : '2rem',
            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngMedium' : 'EngMedium',
          }}>
          {isArabic
            ? <>كل ما تحتاج لمعرفته حول تطبيقنا<br /> لا يمكنك العثور على الإجابة التي تبحث عنها؟ <br />
              <span className='maill' style={{
                fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngSemi' : 'EngSemi',
              }}>support@beanz.ae</span>
              {' '}يرجى الاتصال بنا {' '}
            </>
            : <>Everything you need to know about our app.<br />Can’t find the answer you’re looking for? <br />Please contact us at <span className='maill' style={{
              fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontSemi') : isMobile ? 'EngSemi' : 'EngSemi',
            }}>support@beanz.ae</span></>}
        </p>
      </div>
      <div className={`${isMobile ? 'w-full' : 'w-full'}  ${isArabic ? 'text-right-arabic' : ''}`}
        style={{
          paddingLeft: isArabic ? (isMobile ? '' : '1.5rem') : isMobile ? '' : '',
        }}>
        <div className={`faq`}>
          {faqs.map((faq, index) => (
            <div className={`faq-item ${faq.isOpen ? 'open' : ''} ${isArabic ? 'text-right-arabic' : ''}`} key={index}>
              <div className={`${isArabic ? 'faq-question-arabic' : 'faq-question'} ${faq.isOpen ? 'open' : ''} `} onClick={() => toggleFAQ(index)}>
                <p className={`faq-heading`}
                  style={{
                    fontSize: isArabic ? (isMobile ? '11px' : '22px') : isMobile ? '11px' : '22px',
                    fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
                    textAlign: isArabic ? 'right' : 'left',
                    lineHeight: isMobile ? '1.5rem' : '2rem',
                  }}>
                  {isArabic ? faq.questionAr : faq.questionEn}
                </p>
                <div className={`${isArabic ? 'faq-arrow-arabic' : 'faq-arrow'} ${faq.isOpen ? 'open' : ''}`}></div>
              </div>
              {faq.isOpen && index !== 2 &&(
                <div className='faq-answer'>
                  <p className="faq-text"
                    style={{
                      fontSize: isArabic ? (isMobile ? '10px' : '20px') : isMobile ? '10px' : '20px',
                      fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
                      lineHeight: isMobile ? '1.5rem' : '2rem',
                      textAlign: isArabic ? 'right' : 'left',
                    }}>
                    { isArabic ? faq.answerAr : faq.answerEn}
                  </p>
                  {faq.answerAr2 && isArabic? 
                    <p className="faq-text"
                      style={{
                        fontSize: isArabic ? (isMobile ? '10px' : '20px') : isMobile ? '10px' : '20px',
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
                        lineHeight: isMobile ? '1.5rem' : '2rem',
                        textAlign: isArabic ? 'right' : 'left',
                      }}>
                      { faq.answerAr2 }
                  </p> : null
                }
                </div>
              )}
              {faq.isOpen && index === 2 && !isArabic &&(
                <div className='faq-answer'>
                  <p className="faq-text"
                    style={{
                      fontSize: isArabic ? (isMobile ? '10px' : '20px') : isMobile ? '10px' : '20px',
                      fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
                      lineHeight: isMobile ? '1.5rem' : '2rem',
                      textAlign: isArabic ? 'right' : 'left',
                    }}>
                    Join BEANZ by filling in the form <a href='#become-a-partner'><u>Become a partner</u></a> and we will contact you within 24 hours.
                  </p>
                </div>
              )}
              {faq.isOpen && index === 2 && isArabic &&(
                <div className='faq-answer'>
                  <p className="faq-text"
                    style={{
                      fontSize: isArabic ? (isMobile ? '10px' : '20px') : isMobile ? '10px' : '20px',
                      fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
                      lineHeight: isMobile ? '1.5rem' : '2rem',
                      textAlign: isArabic ? 'right' : 'left',
                    }}>
                    عن طريق ملء <a href='#انضم-كشريك'><u>النموذج</u></a> وسنتصل بك خلال 24 ساعة
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
