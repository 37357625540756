import React, { useEffect, useRef, useState } from 'react';
import { Header } from './Header';
import { Video } from './Video';
import { Coffeeshops } from './Coffeeshops';
import '../index.css';
import Layout from './Layout';
import { CustomersPage } from './CustomersPage';
import Order from './Order';
import { Gallery } from './Gallery';
import PartnerForm from './PartnerForm';
import FAQS from './FAQS';
import { Footer } from './Footer';
import up from '../assets/Arrow.svg'


interface HomeProps {
  isArabic: boolean;
  handleLanguageChange: () => void;
  sectionTranslations: Record<string, string>;
}

export function Home({ isArabic, handleLanguageChange, sectionTranslations }: HomeProps) {
  const MOBILE_BREAKPOINT = 465;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_BREAKPOINT
  );
  const coffeeshopsRef = useRef<HTMLDivElement>(null);
  const customersRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);
  const PartnerFormRef = useRef<HTMLDivElement>(null);
  const topFunction = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
  }
  
  const [hasScrolled, setHasScrolled] = useState(false);
  const handleScroll = () => {
    setHasScrolled(window.scrollY > 0);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (!hash) {
        window.scroll({ top: 0, behavior: 'smooth' });
        return;
      }
      if (hash) {
        const sectionName = hash.substring(1);
        const targetRef = sectionName === 'coffeeshops'
          ? coffeeshopsRef
          : sectionName === 'customers'
            ? customersRef
            : sectionName === 'gallery'
              ? galleryRef
              : sectionName === 'partner'
                ? PartnerFormRef
                : null;

        if (targetRef && targetRef.current) {
          targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    const sectionNameFromURL = window.location.hash.substring(1);
    if (sectionNameFromURL && sectionTranslations[sectionNameFromURL]) {
      const translatedSectionName = sectionTranslations[sectionNameFromURL];
      const targetSection = document.getElementById(translatedSectionName);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [sectionTranslations]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
      setIsMobile(isMobile);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Layout isArabic={isArabic} handleLanguageChange={handleLanguageChange}>
        <div className={`home-container`}>
          <div
            className={`background-image ${isArabic ? (isMobile ? 'flipped' : 'flipped') : isMobile ? '' : ''
              }`}          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"
              style={{
                transform: isArabic ? 'scaleX(-1)' : 'scaleX(-1)',
              }}
              className={isArabic ? (isMobile ? 'arabic_downmob' : 'arabic_down') : isMobile ? 'english_upmob' : 'english_up'}>
              <path fill="white" fillOpacity="1" d="M0,224L80,240C160,256,320,288,480,282.7C640,277,800,235,960,218.7C1120,203,1280,213,1360,218.7L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>
          </div>
          <div className="flex flex-col justify-center items-center">
            <Header
              isArabic={isArabic}
              handleLanguageChange={handleLanguageChange}
              title={isArabic ? 'عنوان بالعربية' : 'Your Specialty'}
              subHeading={isArabic ? 'عنوان فرعي بالعربية' : 'Coffee App'}
              description={isArabic ? 'وصف بالعربية' : 'Description in English'}
              points={500}
              description2="Description 2"
            />
          </div>
        </div>
        {hasScrolled? <button onClick={topFunction} className={isArabic? 'ar' : 'en'} id="myBtn" title="Back to top"><img src={up} alt=""/></button> : null}
        <Video
          isArabic={isArabic}
        />
        <Coffeeshops
          isArabic={isArabic}
          sectionTranslations={sectionTranslations}
          id="coffeeshops"
          ref={coffeeshopsRef}
        />
        <CustomersPage
          isArabic={isArabic}
          sectionTranslations={sectionTranslations}
          id="customers"
          ref={customersRef}
        />
        <Order
          isArabic={isArabic}
        />
        <Gallery
          isArabic={isArabic}
          sectionTranslations={sectionTranslations}
          id="gallery"
          ref={galleryRef}
        />
        <PartnerForm
          isArabic={isArabic}
          sectionTranslations={sectionTranslations}
          id="PartnerForm"
          ref={PartnerFormRef}
        />
        <FAQS
          isArabic={isArabic}
        />
        <Footer
          isArabic={isArabic}
          handleLanguageChange={handleLanguageChange}
        />
      </Layout>
    </>
  );
}
