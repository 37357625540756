import React, { ReactNode } from 'react';
import { Navbar } from './Navbar';

interface propTypes {
    isArabic: boolean;
    handleLanguageChange: () => void;
    children: ReactNode;
}

const Layout = ({ children, isArabic, handleLanguageChange }: propTypes) => {

    return (
        <div >
            <Navbar isArabic={isArabic} handleLanguageChange={handleLanguageChange} />
            {children}
        </div>
    );
};

export default Layout;
