import { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Footer } from '../components/Footer';
import '../index.css'
import { useLocation, useNavigate } from 'react-router-dom';

interface propTypes {
  isArabic: boolean;
  handleLanguageChange: () => void;
  sectionTranslations: Record<string, string>;
  currentSection: string;

}

const GalleryPage = ({ isArabic, handleLanguageChange, sectionTranslations, currentSection }: propTypes) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash && hash.startsWith('#')) {
      const sectionName = hash.substring(1);
      const translatedSectionName = sectionTranslations[sectionName];
      if (translatedSectionName) {
        const targetSection = document.getElementById(translatedSectionName);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [location.hash, sectionTranslations]);

  useEffect(() => {
    const languagePrefix = isArabic ? 'ar' : 'en';
    const galleryUrl = `/${languagePrefix}/gallery`;
    navigate(galleryUrl, { replace: true });
  }, [isArabic, navigate]);

  const handlePopState = () => {
    const currentLanguagePrefix = window.location.pathname.startsWith('/ar') ? 'ar' : 'en';
    const newIsArabic = currentLanguagePrefix === 'ar';

    if (newIsArabic !== isArabic) {
      handleLanguageChange();
      const languagePrefix = newIsArabic ? 'ar' : 'en';
      const galleryUrl = `/${languagePrefix}/gallery`;
      navigate(galleryUrl, { replace: true });
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isArabic, handleLanguageChange, navigate]);
  return (
    <>
      <Layout isArabic={isArabic} handleLanguageChange={handleLanguageChange} >
        <div className="pt-16 mx-auto title-gallery">
          <h1 className={`text-center text-[#335A79] capitalize  text-[40px]`}
            style={{
              fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
              fontSize: isArabic ? (isMobile ? '30px' : '45px') : isMobile ? '30px' : '45px',
            }}>
            {isArabic ? 'معرض الصور' : 'Gallery'}</h1>
          <p className={`max-w-2xl mx-auto my-6 text-center text-[#00121F]  text-[25px]`}
            style={{
              fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
              fontSize: isArabic ? (isMobile ? '14px' : '21px') : isMobile ? '13px' : '21px',
              marginTop: isMobile ? '0.5rem' : '',
              marginBottom: isMobile ? '0.1rem' : '2.5rem',
            }}>
            {isArabic ? 'صور وفيديو من محلات القهوة في تطبيقنا' : 'Photo & Video from our Specialty Coffee'}
          </p>
        </div>
        <div className="grid-nav">
          <div className={`image-1 hide-on-mobile`} />
          <div className={`image-2 hide-on-mobile`} />
          <div className={`image-3 hide-on-mobile`} />
          <div className={`image-4 play-overlay`}>
          </div>
          <div className="image-5" />
          <div className={`image-6 play-overlay`}>
          </div>
          <div className={`image-7 `} />
          <div className={`image-8 `} />
          <div className={`image-9 `} />
          <div className={`image-10 `} />
          <div className={`image-11 play-overlay`}>
          </div>
          <div className="image-12" />
        </div>
        <Footer
          isArabic={isArabic}
          handleLanguageChange={handleLanguageChange}
        />
      </Layout>
    </>
  )
}
export default GalleryPage;
