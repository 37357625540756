export const config: {
  appPath: string;
  baseUrl: string;
  downloadUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  googlePlayUrl: string;
  appStoreUrl: string;
  linkedinUrl: string;
} = {
  appPath: 'en',
  baseUrl: getDefaults(),
  downloadUrl: 'https://beanz.co/qr/website',
  instagramUrl: 'https://www.instagram.com/beanz.ae/',
  twitterUrl: 'https://twitter.com/getbeanz_',
  linkedinUrl: 'https://www.linkedin.com/company/beanz-app/',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=ae.beanz.client',
  appStoreUrl: 'https://apps.apple.com/ae/app/beanz-coffee/id1532652635',
};

if (typeof process.env.REACT_APP_BASE_URL !== 'undefined') {
  config.baseUrl = process.env.REACT_APP_BASE_URL;
}
function getDefaults() {
  const { protocol, hostname } = window.location;
  return `${protocol}//${hostname}`;
}

console.info(`⚠️ CONFIG ${JSON.stringify(config, null, 2)}`);
