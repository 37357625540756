import React, { useEffect, useState } from 'react';
import {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import '../index.css'
import phone from '../assets/Image.png';
import icon1 from '../assets/Experience & Inspire.png';
import icon2 from '../assets/Customize it.png';
import icon3 from '../assets/Secure Checkout.png';
import icon4 from '../assets/Skip the line.png';
import icon5 from '../assets/Free Coffee.png';

interface IconProps {
  style: React.CSSProperties;
}

type Direction = 'ltr' | 'rtl';

export const initialState: { direction: Direction } = {
  direction: 'ltr',
};

export const Context = createContext<{
  state: { direction: Direction };
  setState: Dispatch<SetStateAction<{ direction: Direction }>>;
}>({ state: initialState, setState: () => { } });

const Icon: React.FC<IconProps> = ({ style }) => {
  return <div className="icon" style={style}></div>;
};

export const useGlobalState = () => useContext(Context);

interface propTypes {
  isArabic: boolean;
  id: string;
  ref: any;
  sectionTranslations: Record<string, string>;
}

export function CustomersPage({ isArabic, ref, sectionTranslations }: propTypes): JSX.Element {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const numIcons = 5;
  const iconWrappers: JSX.Element[] = [];
  const iconSize = '100px';

  for (let i = 1; i <= numIcons; i++) {
    const iconStyle: React.CSSProperties = {
      transform: `rotate(calc(245deg/5 * ${i}))`,
      transformOrigin: '448px',
      width: iconSize,
      height: iconSize,
    };
  }

  return (
    <>
      <div className={`py-20 mx-auto scroll-margin-top`} dir={isArabic ? 'ltr' : 'rtl'}
        ref={ref} id={sectionTranslations.customers}>
        <h1 className={`text-center text-[#335A79] capitalize  text-[40px]`}
          style={{
            fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
            fontSize: isArabic ? (isMobile ? '30px' : '45px') : isMobile ? '30px' : '45px',
          }}>
          {isArabic ? 'محبي القهوة' : 'Customers'}</h1>
        <p className={`max-w-2xl mx-auto my-6 text-center text-[#00121F]  text-[15px]`}
          style={{
            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
            fontSize: isArabic ? (isMobile ? '14px' : '21px') : isMobile ? '14px' : '21px',
            marginTop: isMobile ? '0.5rem' : '',
            marginBottom: isMobile ? '2.5rem' : '2.5rem',
          }}>
          {isArabic ? 'استمتع بأفضل القهاوي المختصة في مدينتك' : 'Enjoy the Best Specialty Coffee in Town'}
        </p>
        <div className="main_customer py-40">
          <div className={` ${isArabic ? 'contain' : 'english_contain'}`}>
            <div className="big_phone_icon">
              <img src={phone} alt="phone"
                style={{
                  maxWidth: '525px',
                  top: '-280px',
                  left: isArabic ? '-110px' : '-110px',
                  position: 'absolute',
                }} /></div>
            <div className={`${isArabic ? 'icon_wrapper' : 'english_icon_wrapper'}`}>
              {isArabic ?
                (<>
                  <div className={`customer_child-1 ${isArabic ? 'icon' : 'english_icon'}`} style={{
                    "--i": 1,
                  } as any}>
                    <div className="for_relative">
                      <div style={{
                        "--j": 1,
                      } as any} className="testing_r">
                        <div className="img_wrapper">
                          <img src={icon1} alt="" style={{
                            width: '80px',
                            height: '90px',
                          }} />
                        </div>
                        <h1 className="text-[#2B1300]"
                          style={{
                            fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                            paddingTop: '1rem',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                          }}>
                          {isArabic ? 'تجربة وإلهام' : 'Experience & Inspire'}
                        </h1>
                        <p style={{
                          fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                          lineHeight: '30px',
                          margin: '-15px 0',
                          fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                          width: isArabic ? (isMobile ? '57%' : '') : isMobile ? '' : '',
                        }}>
                          {isArabic ? (isMobile ? (
                            <>
                              قائمة منتقاة بعناية من المقاهي مع أجود أنواع القهوة عالية الجودة
                            </>
                          ) : <>
                            قائمة منتقاة بعناية من المقاهي مع أجود أنواع القهوة عالية الجودة
                          </>
                          ) : (
                            isMobile ? (
                              <>
                                A carefully curated list of coffeeshops with the very finest high-quality coffee
                              </>
                            ) : <>
                              A carefully curated list of coffeeshops with the very finest high-quality coffee
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`customer_child-2 ${isArabic ? 'icon' : 'english_icon'}`} style={{
                    "--i": 2, transform: `rotate(calc(245deg/5 * ${isArabic ? 'var(--i)' : '-var(--i)'}))`,
                  } as any}>
                    <div className="for_relative">
                      <div style={{
                        "--j": 2,
                      } as any} className="testing_r">
                        <div className="img_wrapper">
                          <img src={icon2} alt="" style={{
                            width: '70px',
                            height: '80px',
                          }} />
                        </div>
                        <h1 className="text-[#2B1300]"
                          style={{
                            fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                            paddingTop: '1rem',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                          }}>
                          {isArabic ? 'قائمة مخصصة لك' : 'Customize it'}
                        </h1>
                        <p className="text-[#00121F]" style={{
                          fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                          lineHeight: '30px',
                          margin: '-15px 0',
                          fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                        }}>
                          {isArabic ? (isMobile ? (
                            <>
                              ستحصل دائمًا على ما تريده بالضبط
                              لأنك تستحق الأفضل
                            </>
                          ) : <>
                            ستحصل دائمًا على ما تريده بالضبط <br />
                            لأنك تستحق الأفضل</>
                          ) : (
                            isMobile ? (
                              <>
                                ,You will always get exactly what you want .because you deserve the very best
                              </>
                            ) : <>
                              ,You will always get exactly what you want<br /> .because you deserve the very best
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`customer_child-3 ${isArabic ? 'icon' : 'english_icon'}`} style={{ "--i": 3 } as any}>
                    <div className="for_relative">
                      <div style={{
                        "--j": 3,
                      } as any} className="testing_r">
                        <div className="img_wrapper">
                          <img src={icon3} alt="" style={{
                            width: '70px',
                            height: '80px',
                          }} />
                        </div>
                        <h1 className="text-[#2B1300]"
                          style={{
                            fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                            paddingTop: '1rem',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                          }}>
                          {isArabic ? 'الدفع الآمن عبر الجوال' : 'Secure Checkout'}
                        </h1>
                        <p className="text-[#00121F]" style={{
                          fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                          margin: '-15px 0',
                          lineHeight: '30px',
                          fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                        }}>
                          {isArabic ? (
                            <>
                             ادفع باستخدام بطاقة الائتمان/الخصم أو BEANZ أو محفظة Apple PAY
                            </>
                          ) : (
                            <>
                                Pay using Credit/Debit card, Apple Pay
                                <br /> or BEANZ wallet
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`customer_child-4 ${isArabic ? 'icon' : 'english_icon'}`} style={{ "--i": 4 } as any}>
                    <div className="for_relative">
                      <div style={{
                        "--j": 4,
                      } as any} className="testing_r">
                        <div className="img_wrapper">
                          <img src={icon4} alt="" style={{
                            width: '70px',
                            height: '80px',
                          }} />
                        </div>
                        <h1 className="text-[#2B1300]"
                          style={{
                            fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                            paddingTop: '1rem',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                          }}>
                          {isArabic ? 'احصل على قهوتك بدون انتظار' : 'Skip the line'}
                        </h1>
                        <p className="text-[#00121F]" style={{
                          fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                          lineHeight: '30px',
                          margin: '-15px 0',
                          fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                        }}>
                          {isArabic ? (
                            <>
                              اطلب عند وصولك إلى القهوة<br />
                              تسوق ، سيكون طلبك جاهزًا لك
                            </>
                          ) : (
                            <>
                              Order when you arrive at the coffee<br />
                              shop, your order will be ready for you
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`customer_child-5 ${isArabic ? 'icon' : 'english_icon'}`} style={{ "--i": 5 } as any}>
                    <div className="for_relative">
                      <div style={{
                        "--j": 5,
                      } as any} className="testing_r">
                        <div className="img_wrapper">
                          <img src={icon5} alt="" style={{
                            width: '70px',
                            height: '80px',
                          }} />
                        </div>
                        <h1 className="text-[#2B1300]"
                          style={{
                            fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                            paddingTop: '1rem',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                          }}>
                          {isArabic ? 'قهوة مجانية' : 'Free Coffee'}
                        </h1>
                        <p className="text-[#00121F]" style={{
                          fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                          lineHeight: '30px',
                          margin: '-15px 0',
                          fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                        }}>
                          {isArabic ? (
                            <>
                              BEANZ فقط في
                              <br />
                              كلما اشتريت أكثر كلما وفرت
                            </>
                          ) : (
                            <>
                              ,Just in BEANZ
                              <br />
                              !the more you buy the more you save
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </>)
                :
                (
                  <>
                    <div className={`customer_child-5 ${isArabic ? 'icon' : 'english_icon'}`} style={{ "--i": 5 } as any}>
                      <div className="for_relative">
                        <div style={{
                          "--j": 5,
                        } as any} className="testing_r">
                          <div className="img_wrapper">
                            <img src={icon1} alt="" style={{
                              width: '70px',
                              height: '80px',
                            }} />
                          </div>
                          <h1 className="text-[#2B1300]"
                            style={{
                              fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                              paddingTop: '1rem',
                              fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                            }}>
                            {isArabic ? 'تجربة وإلهام' : 'Experience & Inspire'}
                          </h1>
                          <p className="text-[#00121F]" style={{
                            fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                            lineHeight: '30px',
                            margin: '-15px 0',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                          }}>
                            {isArabic ? 'قائمة منتقاة بعناية من المقاهي مع أجود أنواع القهوة عالية الجودة' : 'A carefully curated list of coffeeshops with the very finest high-quality coffee'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`customer_child-4 ${isArabic ? 'icon' : 'english_icon'}`} style={{ "--i": 4 } as any}>
                      <div className="for_relative">
                        <div style={{
                          "--j": 4,
                        } as any} className="testing_r">
                          <div className="img_wrapper">
                            <img src={icon2} alt="" style={{
                              width: '70px',
                              height: '80px',
                            }} />
                          </div>
                          <h1 className="text-[#2B1300]"
                            style={{
                              fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                              paddingTop: '1rem',
                              fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                            }}>
                            {isArabic ? 'قائمة مخصصة لك' : 'Customize it'}
                          </h1>
                          <p className="text-[#00121F]" style={{
                            fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                            lineHeight: '30px',
                            margin: '-15px 0',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                          }}>
                            {isArabic ? (isMobile ? (
                              <>
                                ستحصل دائمًا على ما تريده بالضبط<br />
                                لأنك تستحق الأفضل
                              </>
                            ) : <>
                              ستحصل دائمًا على ما تريده بالضبط <br />
                              لأنك تستحق الأفضل</>
                            ) : (
                              isMobile ? (
                                <>
                                  ,You will always get exactly what you want because you deserve the very best
                                </>
                              ) : <>
                                ,You will always get exactly what you want<br /> .because you deserve the very best
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`customer_child-3 ${isArabic ? 'icon' : 'english_icon'}`} style={{ "--i": 3 } as any}>
                      <div className="for_relative">
                        <div style={{
                          "--j": 3,
                        } as any} className="testing_r">
                          <div className="img_wrapper">
                            <img src={icon3} alt="" style={{
                              width: '70px',
                              height: '80px',
                            }} />
                          </div>
                          <h1 className="text-[#2B1300]"
                            style={{
                              fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                              paddingTop: '1rem',
                              fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                            }}>
                            {isArabic ? 'الدفع الآمن عبر الجوال' : 'Secure Checkout'}
                          </h1>
                          <p className="text-[#00121F]" style={{
                            fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                            margin: '-15px 0',
                            lineHeight: '30px',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                          }}>
                            {isArabic ? (
                            <>
                             ادفع باستخدام بطاقة الائتمان/الخصم أو BEANZ أو محفظة Apple PAY
                            </>
                          ) : (
                            <>
                                Pay using Credit/Debit card, Apple Pay
                                <br /> or BEANZ wallet
                            </>
                          )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`customer_child-2 ${isArabic ? 'icon' : 'english_icon'}`} style={{
                      "--i": 2, transform: `rotate(calc(245deg/5 * ${isArabic ? 'var(--i)' : '-var(--i)'}))`,
                    } as any}>
                      <div className="for_relative">
                        <div style={{
                          "--j": 2,
                        } as any} className="testing_r">
                          <div className="img_wrapper">
                            <img src={icon4} alt="" style={{
                              width: '70px',
                              height: '80px',
                            }} />
                          </div>
                          <h1 className="text-[#2B1300]"
                            style={{
                              fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                              paddingTop: '1rem',
                              fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                            }}>
                            {isArabic ? 'احصل على قهوتك بدون انتظار' : 'Skip the line'}
                          </h1>
                          <p className="text-[#00121F]" style={{
                            fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                            lineHeight: '30px',
                            margin: '-15px 0',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                          }}>
                            {isArabic ? (
                              <>
                                اطلب عند وصولك إلى القهوة
                                تسوق ، سيكون طلبك جاهزًا لك
                              </>
                            ) : (
                              <>
                                Order when you arrive at the coffee
                                shop, your order will be ready for you
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`customer_child-1 ${isArabic ? 'icon' : 'english_icon'}`} style={{
                      "--i": 1,
                    } as any}>
                      <div className="for_relative">
                        <div style={{
                          "--j": 1,
                        } as any} className="testing_r">
                          <div className="img_wrapper">
                            <img src={icon5} alt="" style={{
                              width: '70px',
                              height: '80px',
                            }} />
                          </div>
                          <h1 className="text-[#2B1300]"
                            style={{
                              fontSize: isArabic ? (isMobile ? '20px' : '22px') : isMobile ? '20px' : '22px',
                              paddingTop: '1rem',
                              fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontSemi') : isMobile ? 'EngBold' : 'EngBold',
                            }}>
                            {isArabic ? 'قهوة مجانية' : 'Free Coffee'}
                          </h1>
                          <p className="text-[#00121F]" style={{
                            fontSize: isArabic ? (isMobile ? '14px' : '20px') : isMobile ? '14px' : '20px',
                            lineHeight: '30px',
                            margin: '-15px 0',
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                          }}>
                            {isArabic ? 'BEANZ فقط في كلما اشتريت أكثر كلما وفرت' : (isMobile ? (
                              <>
                                ,Just in BEANZ
                                the more you buy the more you save
                              </>
                            ) : <>
                              ,Just in BEANZ<br />
                              !the more you buy the more you save
                            </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
