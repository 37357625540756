import React, { useEffect, useState } from 'react';
import '../index.css';
import car from '../assets/Car.png';
import inStore from '../assets/In-store.png';
import table from '../assets/On-the-table.png';


interface propTypes {
  isArabic: boolean;
}

export default function CustomersPage({ isArabic }: propTypes): JSX.Element {
  const [isMobile, setIsMobile] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScrollLeft = () => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = container.clientWidth;
      const maxScroll = container.scrollWidth - container.clientWidth;
      let newScrollLeft = scrollLeft - scrollAmount;

      if (newScrollLeft < 0) {
        newScrollLeft = maxScroll;
      }

      container.scrollLeft = newScrollLeft;
      setScrollLeft(newScrollLeft);
    }
  };

  const handleScrollRight = () => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = container.clientWidth;
      const maxScroll = container.scrollWidth - container.clientWidth;
      let newScrollLeft = scrollLeft + scrollAmount;

      if (newScrollLeft >= maxScroll) {
        newScrollLeft = 0;
      }

      container.scrollLeft = newScrollLeft;
      setScrollLeft(newScrollLeft);
    }
  };

  return (
    <>
      <div className={`py-10 mx-auto bg-[#DDF0F680] `}>
        <h1
          className={`text-center  capitalize  text-[50px] pb-6`}
          style={{
            color: isMobile ? '#335A79' : '#061F21',
            fontFamily: isArabic
              ? isMobile
                ? 'ArabicFontBold'
                : 'ArabicFontSemi'
              : isMobile
                ? 'EngBold'
                : 'EngBold',
            fontSize: isArabic ? (isMobile ? '24px' : '40px') : isMobile ? '24px' : '40px',
          }}
        >
          {isArabic ? (isMobile ? <>في بينز احصل على<br /> طلبك بالطريقة اللي<br /> تناسبك</> : 'احصل على طلبك بالطريقة اللي تناسبك') : isMobile ? <>In BEANZ You Can <br /> Pick Up Your Order In<br /> Many Ways</> : 'Pick Up Your Order In Many Ways'}
        </h1>
        <div className="order_container_wrapper">
          {isMobile && (
            <button className="arrow_button left_button" onClick={handleScrollLeft}>
              <div className="left_icon"></div>
            </button>
          )}
          <div className="order_container" ref={containerRef}>
            <div className="order_box">
              <img src={car} alt="car" />
              <h2 className='text-[#0F2D47]'
                style={{
                  fontFamily: isArabic
                    ? isMobile
                      ? 'ArabicFontBold'
                      : 'ArabicFontBold'
                    : isMobile
                      ? 'EngBold'
                      : 'EngBold',
                }}>
                {isArabic ? 'من سيارتك' : 'From Your Car'}
              </h2>
              <p className="text-[#335A79]"
                style={{
                  fontFamily: isArabic
                    ? isMobile
                      ? 'ArabicFontRegular'
                      : 'ArabicFontRegular'
                    : isMobile
                      ? 'EngRegular'
                      : 'EngRegular',
                  lineHeight: isMobile ? '1.4rem' : '1.7rem',
                }}>
                {isArabic ? (
                  isMobile ? (
                    <>
                      اطلب من تطبيق بينز أينما كنت، ثم تعال لاستلام طلبك أثناء وجودك في سيارتك
                    </>
                  ) : (
                    <>
                      اطلب من تطبيق بينز أينما<br /> كنت، ثم تعال لاستلام طلبك<br /> أثناء وجودك في سيارتك
                    </>
                  )
                ) : (
                  isMobile ? (
                    <>
                      Order from BEANZ app wherever you are, then come to pick up your order while you are in your car.
                    </>
                  ) : (
                    <>
                      Order from BEANZ app wherever you are, then come to pick up your order while you are in your car.
                    </>
                  )
                )}
              </p>
            </div>
            <div className="order_box">
              <img src={inStore} alt="store"
                style={{
                  width: '75px',
                  marginBottom: '5px'
                }} />
              <h2 className='text-[#0F2D47]'
                style={{
                  fontFamily: isArabic
                    ? isMobile
                      ? 'ArabicFontBold'
                      : 'ArabicFontBold'
                    : isMobile
                      ? 'EngBold'
                      : 'EngBold',
                }}>
                {isArabic ? 'في المحل' : 'In-Store'}
              </h2>
              <p className="text-[#335A79]"
                style={{
                  fontFamily: isArabic
                    ? isMobile
                      ? 'ArabicFontRegular'
                      : 'ArabicFontRegular'
                    : isMobile
                      ? 'EngRegular'
                      : 'EngRegular',
                  lineHeight: isMobile ? '1.4rem' : '1.7rem',
                }}>
                {isArabic ? '' : ''}
                {isArabic ? (
                  isMobile ? (
                    <>
                      يمكنك الطلب من بينزفي الوقت الذي تريده ، ثم تعال لاستلام طلبك في المتجر
                    </>
                  ) : (
                    <>
                      يمكنك الطلب من بينز<br />  في الوقت الذي تريده ، ثم<br />  تعال لاستلام طلبك في المتجر
                    </>
                  )
                ) : (
                  isMobile ? (
                    <>
                      In BEANZ, you can order <br />from the app at the time you want, then come to pick up your order in store.
                    </>
                  ) : (
                    <>
                      In BEANZ, you can order from the app at the time you want, then come to pick up your order in store.
                    </>
                  )
                )}
              </p>
            </div>
            <div className="order_box">
              <img src={table} alt="table" />
              <h2 className='text-[#0F2D47]'
                style={{
                  fontFamily: isArabic
                    ? isMobile
                      ? 'ArabicFontBold'
                      : 'ArabicFontBold'
                    : isMobile
                      ? 'EngBold'
                      : 'EngBold',
                }}>
                {isArabic ? 'على الطاولة' : 'On The Table'}
              </h2>
              <p className="text-[#335A79]"
                style={{
                  fontFamily: isArabic
                    ? isMobile
                      ? 'ArabicFontRegular'
                      : 'ArabicFontRegular'
                    : isMobile
                      ? 'EngRegular'
                      : 'EngRegular',
                  lineHeight: isMobile ? '1.4rem' : '1.7rem',
                }}>
                {isArabic ? (
                  isMobile ? (
                    <>
                      يسمح للمستخدمين على الطاولة بالمسح على رمز الاستجابة،القائمة الطلب ، الدفع وكسب الطوابع
                    </>
                  ) : (
                    <>
                      يسمح للمستخدمين على الطاولة<br /> بالمسح على رمز الاستجابة،القائمة<br /> الطلب ، الدفع وكسب الطوابع
                    </>
                  )
                ) : (
                  isMobile ? (
                    <>
                      Allows dine-in users to <br /> scan QR-code, browse menu, order, pay, and earn stamps. All in one place!
                    </>
                  ) : (
                    <>
                      Allows dine-in users to scan <br />
                      QR-code, browse menu, order, pay, and earn stamps. All in one place!
                    </>
                  )
                )}
              </p>
            </div>
          </div>
          {isMobile && (
            <button className="arrow_button right_button" onClick={handleScrollRight}>
              <div className="right_icon"></div>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
