import React, { useEffect, useRef, useState } from 'react';
import '../index.css'
import icon2 from '../assets/2.jpg';
import icon3 from '../assets/Gellary/3.jpg';
import more from '../assets/More.png';
import morear from '../assets/More-ar.png';
import playIcon from '../assets/play.png';
import video from '../assets/gallery.mp4';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface propTypes {
  isArabic: boolean;
  ref: any;
  id: string;
  sectionTranslations: Record<string, string>;
}

export function Gallery({ isArabic, ref, sectionTranslations }: propTypes): JSX.Element {
  const [isMobile, setIsMobile] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const newPath = currentPath.includes('gallery') ? (isArabic ? currentPath.replace('gallery', 'المعرض') : currentPath.replace('المعرض', 'gallery')) : currentPath;
    navigate(newPath);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
  };

  const handleGalleryLinkClick = () => {
    handleLanguageChange();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
      } else {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      }
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const videoContainer = document.getElementById(sectionTranslations.gallery); // Use the correct ID
    if (videoContainer) {
      observer.observe(videoContainer);
    }
    return () => {
      if (videoContainer) {
        observer.unobserve(videoContainer);
      }
    };
  }, [videoRef, sectionTranslations.gallery]);

  return (
    <>
      <div className={`py-10 mx-auto`} ref={ref} id={sectionTranslations.gallery}
        style={{
          paddingLeft: isMobile ? '1.5rem' : '5rem',
          paddingRight: isMobile ? '1.5rem' : '5rem',
        }}>
        <h1 className={`text-center text-[#335A79] capitalize  text-[40px]`}
          style={{
            fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
            fontSize: isArabic ? (isMobile ? '30px' : '45px') : isMobile ? '30px' : '45px',
          }}>
          {isArabic ? 'معرض الصور' : 'Gallery'}</h1>
        <p className={`max-w-2xl mx-auto my-6 text-center text-[#00121F]  text-[15px]`}
          style={{
            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
            fontSize: isArabic ? (isMobile ? '14px' : '21px') : isMobile ? '14px' : '21px',
            marginTop: isMobile ? '0.5rem' : '',
            marginBottom: isMobile ? '2.5rem' : '2.5rem',
          }}>
          {isArabic ? 'صور ومقاطع من شركائنا' : 'Photo & Video from our partners'}
        </p>
        <div className={`grid-container-gallery pt-6 ${isArabic ? 'grid-container-gallery-ar' : 'grid-container-gallery'}`}>
          <div className='item1'>
            <div className="video-thumbnail" onClick={handlePlayVideo}>
              {isVideoPlaying ? (
                <video
                  src={video}
                  ref={videoRef}
                  className="gallery-video"
                  controls
                  autoPlay
                  playsInline
                ></video>
              ) : (
                <>
                  <video
                    src={video}
                    className="gallery-video"
                  ></video>
                  <img
                    src={playIcon}
                    alt="Play Icon"
                    className="play-iconn"
                    width={85}
                    height={85}
                    onClick={(event) => {
                      const videoElement = event.currentTarget.previousSibling;
                      if (videoElement && videoElement instanceof HTMLVideoElement) {
                        videoElement.play();
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className='item2'>
            <img src={icon2} alt='Icon 2' />
          </div>
          <div className='item3'>
            <img src={icon3} alt='Icon 3' />
          </div>
          <Link to={`${isArabic ? '/ar/gallery' : '/en/gallery'}`} onClick={() => { handleGalleryLinkClick(); handleLanguageChange(); }} className={`${isArabic ? 'button-container-arabic' : 'button-container'}`}>
            <button className={` text-[#00121F] ${isArabic ? 'order-2 view-more-button-text-ar' : 'view-more-button-text'}`}
              style={{
                fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
              }}>
              {isArabic ? 'مشاهدة المزيد' : 'View More'}
            </button>
            <button className={`view-more-button-icon ${isArabic ? 'order1' : ''}`}>
              {isArabic ? <><img src={morear} alt="arrow-left" width={25} height={25} /></> : <><img src={more} alt="arrow-right" width={25} height={25} /></>}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
