import { useEffect, useState } from 'react';
import phone from '../assets/Image.png';
import Icon from '../assets/Star.png';
import store from '../assets/Store.png';
import AppStore from '../assets/App-Store.svg'
import '../index.css'
import { config } from '../config';

interface propTypes {
  isArabic: boolean;
  handleLanguageChange: () => void;
  title?: string;
  subHeading?: string;
  description?: string;
  points?: number;
  description2?: string;
}

export function Header({ isArabic, handleLanguageChange, points }: propTypes) {
  const MOBILE_BREAKPOINT = 677;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_BREAKPOINT
  );
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
      setIsMobile(isMobile);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const loginRedirect = () => {
      window.location.assign(`${config.baseUrl}/portal/login`);
  }
  return (
    <>
      <section className="wave-container" >
        <div className={`grid max-w-screen-xl  mx-auto lg:gap-8 xl:gap-[50px]  lg:grid-cols-12 lg:${isArabic ? 'order-last text-right ' : 'order-first'} `}>
          <div className={`mr-auto no-line-height place-self-center sect lg:col-span-6 ${isArabic ? 'order-last text-right' : 'order-first'}`}
            style={{
              padding: isMobile ? '36px' : '',
              paddingTop: isMobile ? '2.5rem' : '',
            }}
          >
            <h1 className={`max-w-2xl mb-4 text-[#335A79] text-4xl  tracking-tight leading-none md:text-5xl xl:text-[3.6rem]`}
              style={{
                fontFamily: isArabic ? (isMobile ? 'ArabicFontBlack' : 'ArabicFontBlack') : isMobile ? 'EngBlack' : 'EngBlack',
              }}>
              {isArabic ? ' أفضل تطبيق' : 'Your Specialty'}
            </h1>
            <h2 className={`max-w-2xl mb-4 text-[#335A79] text-4xl  tracking-tight leading-none md:text-5xl xl:text-6xl `}
              style={{
                fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
              }}>
              {isArabic ? '  للقهوة المختصة' : 'Coffee App'}
            </h2>
            <br />
            <p className={`max-w-2xl md:leading-6 lg:leading-7  leading-6  mb-6  text-[#00121F] lg:mb-8 md:text-lg lg:text-[1.18rem] ${isArabic ? 'parheadar' : 'parhead'}`}
              style={{
                fontFamily: isArabic ? (isMobile ? 'ArabicFontLight' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
              }}>
              {
                isArabic
                  ? isMobile
                    ? `يعمل التطبيق على تبسيط عملية طلب المقاهي داخل المتجر أو الرصيف أو تناول الطعام في الداخل، ويعزز الاحتفاظ بالعملاء من خلال مكافآت مخصصة `
                    : `يعمل التطبيق على تبسيط عملية طلب المقاهي داخل المتجر أو الرصيف أو تناول الطعام في الداخل، ويعزز الاحتفاظ بالعملاء من خلال مكافآت مخصصة `
                  : isMobile
                    ? `BEANZ App Simplifies Coffee Shop Ordering For In-Store, Curbside Or Dine-In, And Enhances Customer Retention With Customized Rewards`
                    : `BEANZ App Simplifies Coffee Shop Ordering For In-Store, Curbside Or Dine-In, \n And Enhances Customer Retention With Customized Rewards`
              }
            </p>
            <button
              className={`inline-flex items-center bg-[#0F2D47] justify-center py-3 mr-3  text-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 btn-log `}
              onClick={loginRedirect}
              style={{
                paddingLeft: isArabic ? (isMobile ? '1.75rem' : '2.5rem') : isMobile ? '1.70rem' : '2.5rem',
                paddingRight: isArabic ? (isMobile ? '1.75rem' : '2.5rem') : isMobile ? '1.70rem' : '2.5rem',
                marginLeft: isArabic ? (isMobile ? '-4px' : '0px') : isMobile ? '0px' : '0px',
                marginRight: isArabic ? (isMobile ? '0px' : '0px') : isMobile ? '0px' : '0px',
                width: isArabic ? (isMobile ? '214px' : '240px') : isMobile ? '160px' : '200px',
                height: isArabic ? (isMobile ? '40px' : '47px') : isMobile ? '44px' : '46px',
                fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontSemi') : isMobile ? 'EngSemi' : 'EngSemi',
                fontSize: isMobile? '14px' : '16px'
              }}
            >
              {isArabic ? ' سجل الدخول كشريك' : 'Partner Login'}
            </button>
          </div>
          <div
            className={`sm:flex lg:mt-0 lg:col-span-6 pb-1 flex ${isArabic ? 'order-last lg:order-first justify-start' : 'order-last lg:order-last justify-end'
              }`}
            style={{
              margin: isMobile ? '0px' : '0px',
              marginRight: isArabic ? (isMobile ? '0px' : '0px') : isMobile ? '0px' : '0px',
              marginLeft: isArabic ? (isMobile ? '8px' : '65px') : isMobile ? '' : '',
              marginTop: isMobile ? '0px' : '0px',
              paddingBottom: isArabic ? (isMobile ? '0.25rem' : '2.5rem') : isMobile ? '0.25rem' : '2.5rem',
              paddingLeft: isArabic ? (isMobile ? '4.5rem' : '2.5rem') : isMobile ? '3.5rem' : '2.5rem',
            }}
          >
            
            <div
              className="relative "
              style={{
                marginLeft: isArabic ? (isMobile ? '-50px' : '') : isMobile ? '' : '',
                top: isArabic ? (isMobile ? '30px' : '100px') : isMobile ? '40px' : '100px',
                left: isArabic ? (isMobile ? '-50px' : '-25px') : isMobile ? '' : '-40px',
              }}
            >
              <img src={phone} alt="mockup" width={400} height={600}
                className={`phone ${isArabic ? 'phone-arabic' : ''}`}
                style={{
                  maxWidth: '100%',
                  display: 'block',
                  width: isMobile ? '100%' : '',
                  height: isMobile ? '525px' : '',
                }}
              />
              
              <div
                className="absolute bottom-0 p-4 iconss"
                style={{
                  left: isArabic ? (isMobile ? '132px' : '196px') : isMobile ? '-63px' : '-162px',
                  right: isArabic ? (isMobile ? '208px' : 'auto') : 'auto',
                  top: isArabic ? (isMobile ? '360px' : '409px') : isMobile ? '359px' : '445px',
                  width: isMobile ? '220px' : '290px',
                  padding: isMobile ? '' : '15px',
                  textAlign: isArabic ? 'right' : 'left',
                }}
              >
              <div
                  className={`store-image ${isArabic && isMobile ? 'arabic-mobile' : ''}`}
                  style={{
                    left: isArabic ? (isMobile ? '-28px' : '-88px') : isMobile ? '209px' : '366px',
                    top: isArabic ? (isMobile ? '-339px' : '-367px') : isMobile ? '-337px' : '-403px',
                    width: isArabic ? (isMobile ? '63px' : '62px') : isMobile ? '63px' : '62px',
                    height: isArabic ? (isMobile ? '62px' : '62px') : isMobile ? '62px' : '62px',
                    padding: isArabic ? (isMobile ? '18px' : '18px') : isMobile ? '19px' : '18px',
                  }}
                >
                 <img
                  src={AppStore}
                  alt="store"
                  className='appStore'
                  width={30}
                  height={30}
                />
                </div>
                <div
                  className={`store-image ${isArabic && isMobile ? 'arabic-mobile' : ''}`}
                  style={{
                    left: isArabic ? (isMobile ? '-91px' : '-153px') : isMobile ? '274px' : '434px',
                    top: isArabic ? (isMobile ? '-339px' : '-367px') : isMobile ? '-337px' : '-403px',
                    width: isArabic ? (isMobile ? '63px' : '62px') : isMobile ? '63px' : '62px',
                    height: isArabic ? (isMobile ? '62px' : '62px') : isMobile ? '62px' : '62px',
                    padding: isArabic ? (isMobile ? '18px' : '18px') : isMobile ? '19px' : '18px',
                  }}
                >
                  <img
                    src={store}
                    alt="store"
                    className=''
                    width={30}
                    height={30}
                  />
                </div>
                <div
                  className={`shadow-md border-2 rounded-lg opacity-100 py-5  p-3 bg-white ${isArabic ? 'text-right' : ''}`}
                  style={{
                    borderRadius: '12px',
                    width: isArabic ? (isMobile ? '220px' : '262px') : isMobile ? '200px' : '262px',
                    paddingLeft: isMobile ? '' : '24px',
                  }}
                >
                  <div className={`flex items-center ${isArabic ? 'text-right' : ''}`}
                    style={{
                      display: 'flex',
                      justifyContent: isArabic ? 'right' : ''
                    }}
                  >
                    <img src={Icon} alt="mockup" width={25} height={25} className="mr-2" />
                    <span
                      className={`text-[#335A79] no-line-height span-point text-xl flex ${isArabic ? 'orderrr no-line-height-arabic' : ''
                        }`}
                      style={{
                        fontSize: isArabic ? (isMobile ? '14px' : '21px') : isMobile ? '14px' : '21px',
                        direction: isArabic ? 'rtl' : 'ltr',
                        textAlign: isArabic ? 'right' : 'left',
                        marginRight: '8px',
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                      }}
                    >
                      {isArabic ? `+${points} ` + `نقطة` : `+${points} points`}
                    </span>
                  </div>
                  <p className={`text-[#7B8091] no-line-height   descr2 ${isArabic ? 'no-line-height-arabic' : ''}`}
                    style={{
                      fontSize: isArabic ? (isMobile ? '9px' : '11px') : isMobile ? '9px' : '11px',
                      paddingRight: isArabic ? '10px' : '',
                      fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                    }}
                  >
                    {isArabic ? 'اجمع نقاط وحولهم كاش عبر التطبيق' : 'Collect points to convert them to cash'}
                  </p>
                  <button className={`inline-flex no-line-height  items-center bg-[#0F2D47] justify-center  py-3  text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 btn-download ${isArabic ? 'no-line-height-arabic' : ''}`}
                    style={{
                      fontSize: isArabic ? (isMobile ? '9px' : '12px') : isMobile ? '9px' : '12px',
                      width: isArabic ? (isMobile ? '95px' : '') : isMobile ? '' : '121px',
                      height: isArabic ? (isMobile ? '29px' : '35px') : isMobile ? '' : '35px',
                      fontFamily: isArabic ? (isMobile ? 'ArabicFontLight' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      marginRight: isArabic ? '8px' : '',
                    }}
                    onClick={() => {
                      const userAgent = navigator.userAgent;
                      let url;
                      if (/android/i.test(userAgent)) {
                       url = `${config.googlePlayUrl}`
                      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
                        url = `${config.appStoreUrl}`
                      } else {
                        url = `${config.appStoreUrl}`
                      }
                      window.open(url, '_blank');
                    }}
                  >
                    {isArabic ? '  حمل التطبيق الآن' : 'Download now'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
