import React, { useEffect, useState, useRef } from 'react';
import coffee from '../assets/Order.png';
import stickers from '../assets/Sticker.png';
import mug from '../assets/Gift.png';
import logo from '../assets/Logo.svg'
import video from '../assets/Promo.mp4';
import '../index.css'

interface propTypes {
    isArabic: boolean;
}

export function Video({ isArabic }: propTypes) {
    const [isMobile, setIsMobile] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1280);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <section className="pb-40 pt-7">
                <div className="to_gap container flex flex-col items-center justify-center py-4 pl-4 mx-auto space-y-8 sm:p-10"
                >
                    <div className={` flex flex-row justify-around overr self-auto  ${isMobile ? '' : 'hide-scrollbar'}`}
                        style={{
                            display: 'flex',
                            justifyContent: isMobile ? 'justify-start' : '',
                            alignSelf: 'normal',
                            overflowX: isMobile ? 'auto' : 'hidden',
                            alignItems: 'center',
                            gap: '7px',
                            marginBottom: isMobile ? '-30' : '',
                        }}>
                        <div className="flex flex-col justify-center mt-8 text-center"
                        >
                            <div className="image-circle bg-[#DDF0F6] self-center flex-shrink-0 w-20 h-20 mb-4 rounded-full flex items-center justify-center">
                                <img
                                    alt=""
                                    className="w-full h-full object-contain coffee"
                                    src={coffee}
                                />
                            </div>
                            <div className="paragraph-box">
                                <p className={`text-xl  leadi text-[#0F2D47]`}
                                    style={{
                                        lineHeight: '2rem',
                                        fontSize: isMobile ? '20px' : '20px',
                                        fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontSemi') : isMobile ? 'EngSemi' : 'EngSemi',
                                    }}>
                                    {isArabic ? 'اطلب' : 'Order'}
                                </p>
                                <p className={`text-[#999999]`}
                                    style={{
                                        lineHeight: '1.1rem',
                                        fontSize: isArabic ? (isMobile ? '' : '16.5px') : isMobile ? '13px' : '14.5px',
                                        fontFamily: isArabic ? (isMobile ? 'ArabicFontLight' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                                        marginLeft: isMobile ? '8px' : '',
                                        marginTop: isArabic ? '3px' : '',
                                    }}
                                >
                                    {isArabic ? 'اطلب واستلم طلبك بدون انتظار' : 'Order ahead and skip the line'}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center mt-8 text-center">
                            <div className="image-circle bg-[#D7D7D98A] self-center flex-shrink-0 w-20 h-20 mb-4 rounded-full flex items-center justify-center">
                                <img
                                    alt=""
                                    className="w-full h-full object-contain sticker"
                                    src={stickers}
                                />
                            </div>
                            <div className="paragraph-box">
                                <p className={`text-xl leadi text-[#0F2D47]`}
                                    style={{
                                        lineHeight: '2rem',
                                        fontSize: isMobile ? '20px' : '20px',
                                        fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontSemi') : isMobile ? 'EngSemi' : 'EngSemi',
                                    }}>
                                    {isArabic ? 'أختام' : 'Stickers'}
                                </p>
                                <p className={`center text-[#999999]`}
                                    style={{
                                        lineHeight: '1.1rem',
                                        fontSize: isArabic ? (isMobile ? '' : '16.5px') : isMobile ? '13px' : '14.5px',
                                        fontFamily: isArabic ? (isMobile ? 'ArabicFontLight' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                                        marginTop: isArabic ? '3px' : '',
                                    }}>
                                    {isArabic ? 'اجمع الأختام واحصل على قهوة مجانية' : isMobile ? 'Collect stamp cards to earn free coffee' : 'You Can Collect stamp cards to earn free coffee'}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center mt-8 text-center ">
                            <div className="image-circle bg-[#FEE3C9] self-center flex-shrink-0 w-20 h-20 mb-4 rounded-full flex items-center justify-center">
                                <img alt="" className="w-full h-full object-contain mug" src={mug} />
                            </div>
                            <div className="paragraph-box ">
                                <p className={`text-xl leadi text-[#0F2D47]`}
                                    style={{
                                        lineHeight: '2rem',
                                        fontSize: isMobile ? '20px' : '20px',
                                        fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontSemi') : isMobile ? 'EngSemi' : 'EngSemi',
                                    }}>
                                    {isArabic ? 'هدايا' : 'Gifts'}
                                </p>
                                <p className={`text-[#999999]`}
                                    style={{
                                        lineHeight: '1.1rem',
                                        fontSize: isArabic ? (isMobile ? '' : '16.5px') : isMobile ? '12.1px' : '14.5px',
                                        fontFamily: isArabic ? (isMobile ? 'ArabicFontLight' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                                        marginTop: isArabic ? '3px' : '',
                                    }}>
                                    {isArabic ? 'ارسل واستقبل الهدايا مع اصدقاءك' : 'Send and receive gifts with your friends'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`not_margined flex justify-center mt-8 pt-14 relative lg:flex-column vidd ${isArabic ? 'row-reverse' : 'row'} video-section-container`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: isArabic ? (isMobile ? 'column' : 'row-reverse') : isMobile ? 'column' : 'row',
                            alignSelf: isArabic ? 'center' : 'center',
                        }}>
                        <div
                            style={{
                                marginLeft: isArabic ? (isMobile ? '1rem' : '2rem') : isMobile ? '1rem' : '-4rem',
                                marginRight: isArabic ? (isMobile ? '1rem' : '-4rem') : isMobile ? '1rem' : '2rem',
                        }}
                        >
                        <div className="relative">
                                <video
                                src={video}
                                ref={videoRef}
                                className="videoo"
                                controls
                                playsInline
                                autoPlay
                                loop
                                muted
                                ></video>
                        </div>
                        </div>
                        <div className="mr-8 ml-[-1.5rem] section2"
                            style={{
                                flexDirection: isArabic ? (isMobile ? 'column' : 'row-reverse') : isMobile ? 'column' : 'row',
                                alignSelf: isArabic ? (isMobile ? 'flex-end' : 'auto') : isMobile ? 'flex-start' : 'auto',
                                marginRight: isArabic ? (isMobile ? '58px' : '') : isMobile ? 'auto' : 'auto',
                                marginLeft: isMobile ? '0px' : '-33px',
                                marginTop: isMobile ? '30px' : '0px',
                                marginBottom: isMobile ? '0px' : '116px',
                            }}>
                            <div className="line-before-heading"
                                style={{
                                    display: 'flex',
                                    flexDirection: isArabic ? 'row-reverse' : 'row',
                                    marginRight: isArabic ? '-60px' : ' ',
                                }}>
                                <hr className="line" />
                                <img src={logo} alt="Logo" width={72} height={72} />
                            </div>
                            <div className="bg-white p-4 rounded-lg">
                                <div className="relative"
                                    style={{
                                        paddingBottom: isArabic ? (isMobile ? '' : '1.7rem') : isMobile ? '' : '1.7rem',
                                        top: isArabic ? (isMobile ? '-15px' : '') : isMobile ? '-15px' : '',
                                    }}>
                                    <p className={`par text-xl text-[#0F2D47] absolute   box-border   bg-white`}
                                        style={{
                                            fontSize: isArabic ? (isMobile ? '25px' : '25px') : isMobile ? '23px' : '26px',
                                            lineHeight: isArabic ? (isMobile ? '2.9rem' : '2.7rem') : isMobile ? '2.9rem' : '2.7rem',
                                            textAlign: isArabic ? 'end' : 'start',
                                            marginLeft: isArabic ? (isMobile ? '-98px' : '-56px') : isMobile ? '' : 'auto',
                                            paddingLeft: isMobile ? '3.7rem' : 'auto',
                                            fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                                        }}
                                    >
                                        {isArabic ? ' بينز اسرع طريق لتحصل على كوبك القهوة' : 'The fastest way to get your coffee cup'}
                                    </p>
                                </div>
                                <ul className={`list-none ml-1 align-left text-[#00121F]`}
                                    style={{
                                        position: 'relative',
                                        top: isMobile ? '125px' : '140px',
                                        textAlign: isArabic ? 'end' : 'start',
                                        marginRight: isArabic ? '-59px' : 'start',
                                        fontFamily: isArabic ? (isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
                                    }}    >
                                    <li className="mb-1">{isArabic ? "تبسيط إدارة الطلبات -" : "- Simplify Order Management"}</li>
                                    <li className="mb-1">{isArabic ? "ولا العميل -" : "- Customer Loyalty"}</li>
                                    <li className="mb-1">{isArabic ? "تحسين بالبيانات -" : "- Improve with Data"}</li>
                                    <li className="mb-1">{isArabic ? "موجدين على مدار 24 /7 -" : "- 24/7 Support"}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}