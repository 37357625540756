import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import '../index.css';
import cup from '../assets/cup.png'
import { config } from '../config';
import { Loader } from './Loader';

interface propTypes {
  isArabic: boolean;
  id: string;
  ref: any;
  sectionTranslations: Record<string, string>;
}

export default function PartnerForm({ isArabic, ref, sectionTranslations }: propTypes) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1190);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const defaultState = {
    shopName: '',
    contactName: '',
    email: '',
    phone: '',
    country: '',
    instagram: '',
    city: ''
  };

  const [{ shopName, contactName, email, phone, country, city, instagram }, setData] = useState(defaultState);
  
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, validator?: Function) => {
    const { name, value } = e.target;
    if (validator && !validator(value)) return;
    setData(prevState => ({ ...prevState, [name]: value }));
  }

  const phoneValidator = (value: string) => {
    const partialPhoneRegex = /^\+?\d{0,4}[-.\s]?\(?(\d{0,3})\)?[-.\s]?(\d{0,4})[-.\s]?(\d{0,4})[-.\s]?(\d{0,9})$/;
    return partialPhoneRegex.test(value);
  };
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const data = {
      contact: {
        name: contactName,
        email,
        phone,
        instagram
      },
      shop: {
        name: shopName,
        country,
        city
      }
    };

    setError('');
    setMessage('');
    setLoading(true);
    let response: Response | null = null;
    try {
      response = await fetch(`${config.baseUrl}/api/partners`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
    } catch {
      setError(isArabic ? 'لم يتم استلام طبك' : 'Failed to send the request');
    } finally {
      setLoading(false);
      setData(defaultState)
    }

    if (response?.ok) {
      setMessage(isArabic ? 'تم استلام طلبك' : 'Your request has been sent successfully');
    }
  }

    const loginRedirect = () => {
      window.location.assign(`${config.baseUrl}/portal/login`);
  }

  return (
    <div  className={`py-10 backcolor  scroll-margin-top ${isArabic ? 'flex-row-reverse pr-10' : 'pl-10'}`} ref={ref} id={sectionTranslations.partner}
      style={{
        paddingRight: isArabic ? (isMobile ? '' : '') : isMobile ? '0' : '2.5rem',
        paddingLeft: isArabic ? (isMobile ? '0' : '2.5rem') : isMobile ? '2.5rem' : '2.5rem',
      }}>
      <div className={`partner-section ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}
        style={{
          marginLeft: isArabic ? (isMobile ? '' : '') : isMobile ? '-2.5rem' : '2.5rem',
          marginRight: isArabic ? (isMobile ? '-2.5rem' : '2.5rem') : isMobile ? '' : '',
        }}>
        <div className="partner-form">
          <div className="partner-text">
            <h2 className='text-[#FFFFFF] '
              style={{
                fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                fontSize: isArabic ? (isMobile ? '28px' : '45px') : isMobile ? '28px' : '45px',
                textAlign: isArabic ? 'right' : 'left',
                paddingBottom: isMobile ? '0rem' : '',
              }}>
              {isArabic ? 'انضم كشريك' : 'BECOME A PARTNER'}
            </h2>
            <p className='text-[#C7E9F5]'
              style={{
                fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
                textAlign: isArabic ? 'right' : 'left',
                fontSize: isArabic ? (isMobile ? '24px' : '40px') : isMobile ? '24px' : '40px',
              }}>
              {isArabic ? 'اشترك الان' : 'Join us now'}
            </p>
          </div>
          <form onSubmit={handleSubmit} >
            <div>
              <p className='text-[#FFFFFF] mb-8'
                style={{
                  fontSize: isArabic ? (isMobile ? '15px' : '20px') : isMobile ? '15px' : '20px',
                  fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
                  lineHeight: '2rem',
                  textAlign: isArabic ? 'right' : 'left',
                  marginBottom: isArabic ? (isMobile ? '1rem' : '') : isMobile ? '1rem' : '',
                }}>
                {isArabic ? 'يرجى ملء النموذج التالي وسوف نتصل بك في غضون 24 ساعة' : 'Please fill in the following form and we will contact you within 24 hours:'}
              </p>
            </div>
            <div className="input-row">
              {isArabic ? (
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="phone"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'رقم الجوال' : 'Phone*'}
                    </label>
                    <input type="text" name="phone" value={phone} id="phone" onChange={(e) => handleChange(e, phoneValidator)} placeholder={isArabic ? 'ادخل رقم الجوال' : '#phone'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="shopName"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'اسم محل القهوة' : 'Coffee shop name*'}
                    </label>
                    <input type="text" name="shopName" value={shopName} id="shopName" onChange={handleChange} placeholder={isArabic ? 'اسم محل القهوة' : '#cafename'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                </>
              ) : (
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="shopName"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'اسم محل القهوة' : 'Coffee shop name*'}
                    </label>
                    <input type="text" id="shopName" name="shopName" value={shopName} onChange={handleChange} placeholder={isArabic ? 'اسم محل القهوة' : '#cafename'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="phone"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'رقم الجوال' : 'Phone*'}
                    </label>
                    <input type="text" value={phone} id="phone" name="phone" onChange={(e) => handleChange(e, phoneValidator)} placeholder={isArabic ? 'ادخل رقم الجوال' : '#phone'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                </>
              )}
            </div>
            <div className="input-row">
              {isArabic ? (
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="county"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'المنطقة' : 'Country*'}
                    </label>
                    <input type="text" id="county" name="country" value={country} onChange={handleChange} placeholder={isArabic ? 'ادخل اسم المنطقة' : '#country'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="contactName"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'اسم التواصل' : 'Contact name*'}
                    </label>
                    <input name='contactName' value={contactName} onChange={handleChange} type="text" id="contactName" placeholder={isArabic ? 'ادخل اسمك' : '#contactName'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                </>
              ) : (
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="contactName"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'اسم التواصل' : 'Contact name*'}
                    </label>
                    <input type="text" name='contactName' value={contactName} onChange={handleChange} id="contactName" placeholder={isArabic ? 'ادخل اسمك' : '#contactName'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="county"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'المنطقة' : 'Country*'}
                    </label>
                    <input type="text" id="county" name="country" onChange={handleChange} value={country} placeholder={isArabic ? 'ادخل اسم المنطقة' : '#country'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                </>
              )}
            </div>
            <div className="input-row">
              {isArabic ? (isMobile ?
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="city"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'المدينة' : 'City*'}
                    </label>
                    <input type="text" id="city" name='city' value={city} onChange={handleChange} placeholder={isArabic ? 'ادخل اسم المدينة' : '#city'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="email"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'البريد الالكتروني(اختياري)' : 'Email Address(optional)'}
                    </label>
                    <input type="email" name='email' value={email}  id="email" onChange={handleChange} placeholder={isArabic ? 'ادخل بريدك الالكتروني' : '@gmail.com'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} />
                  </div>
                </>
                :
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="city"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'المدينة' : 'City*'}
                    </label>
                    <input type="text" id="city" name='city' value={city} onChange={handleChange} placeholder={isArabic ? 'ادخل اسم المدينة' : '#city'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="email"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'البريد الالكتروني(اختياري)' : 'Email Address(optional)'}
                    </label>
                    <input type="email" id="email" name='email' value={email} onChange={handleChange} placeholder={isArabic ? 'ادخل بريدك الالكتروني' : '@gmail.com'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} />
                  </div>
                </>
              ) : isMobile ? (
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="email"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'البريد الالكتروني(اختياري)' : 'Email Address(optional)'}
                    </label>
                    <input type="email" id="email" name="email" value={email} onChange={handleChange} placeholder={isArabic ? 'ادخل بريدك الالكتروني' : '@gmail.com'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} />
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="city"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'المدينة' : 'City*'}
                    </label>
                    <input type="text" id="city" name="city" value={city} onChange={handleChange}  placeholder={isArabic ? 'ادخل اسم المدينة' : '#city'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                    {/* <div className={`${isArabic ? 'partner-button-container-arabic' : 'partner-button-container'}`}>
                      {error ? <span className='error-msg'>{error}</span> :
                       loading ? <Loader size={40} /> :
                        message ? <span className='success-msg'>{message}</span> :
                          null
                    }
                      {message ? null :
                        <button type="submit" className="partner-styled-button"
                          style={{
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                          }}
                        disabled={loading}>
                          {isArabic ? 'ارسال' : 'Submit'}
                        </button>}
                    <p className={`${isArabic ? 'centered-text-arabic' : 'centered-text'} text-[#788E9F] capitalize`}
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                        lineHeight: isArabic ? (isMobile ? '1rem' : '2rem') : isMobile ? '1rem' : '2rem',
                        marginTop: isArabic ? (isMobile ? '0.5rem' : '0.5rem') : isMobile ? '0.5rem' : '0.5rem',
                        marginRight: isArabic ? (isMobile ? '0rem' : '4rem') : isMobile ? '0rem' : '3rem',
                      }}>
                      {isArabic ?
                        <>
                          شريك سابق؟ <a className="text-[#C7E9F5] cursor-pointer" onClick={loginRedirect}>سجل الدخول</a>
                        </> :
                        <>
                          I’m already A partner?&nbsp; <a className='text-[#C7E9F5] cursor-pointer' onClick={loginRedirect}> Login</a>
                        </>
                      }
                    </p>
                  </div> */}
                </>
              ) :
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="email"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'البريد الالكتروني(اختياري)' : 'Email Address(optional)'}
                    </label>
                    <input type="email" id="email" name="email" value={email} onChange={handleChange} placeholder={isArabic ? 'ادخل بريدك الالكتروني' : '@gmail.com'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} />
                    {/* <div className={`${isArabic ? 'partner-button-container-arabic' : 'partner-button-container'}`}
                      style={{ display: 'flex', flexDirection: 'column', width: '81%' }}>
                        {error ? <span className='error-msg'>{error}</span> :
                       loading ? <Loader size={40} /> :
                        message ? <span className='success-msg'>{message}</span> :
                          null
                        }
                        {message ? null :
                          <button type="submit" className="partner-styled-button"
                            style={{
                              fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                            }}
                            disabled={loading}>
                            {isArabic ? 'ارسال' : 'Submit'}
                          </button>}
                      <div className="centered-paragraph-container">
                        <p className={`${isArabic ? 'centered-text-arabic' : 'centered-text'} text-[#788E9F] capitalize`}
                          style={{
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                            lineHeight: isArabic ? (isMobile ? '1rem' : '2rem') : isMobile ? '1rem' : '2rem',
                            marginTop: isArabic ? (isMobile ? '0.5rem' : '0.5rem') : isMobile ? '0.5rem' : '0.5rem',
                            margin: 'auto',
                          }}>
                          {isArabic ?
                            <>
                              شريك سابق؟ <a className="text-[#C7E9F5] cursor-pointer" onClick={loginRedirect}>سجل الدخول</a>
                            </> :
                            <>
                              I’m already A partner?&nbsp; <a className='text-[#C7E9F5] cursor-pointer' onClick={loginRedirect}> Login</a>
                            </>
                          }
                        </p>
                      </div>
                    </div> */}
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="city"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'المدينة' : 'City*'}
                    </label>
                    <input type="text" id="city" name="city" value={city} onChange={handleChange}  placeholder={isArabic ? 'ادخل اسم المدينة' : '#city'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                </>
              }
            </div>
            <div className="input-row">
              {isArabic ? (isMobile ?
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? ' حساب الانستغرام (اختياري)' : 'Instagram Account(optional)'}
                    </label>
                    <input type="instagram" name='instagram' value={instagram}  id="instagram" onChange={handleChange} placeholder={isArabic ? '@معرف الانستاغرام' : '@yurInstagram'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} />
                    <div className={`${isArabic ? 'partner-button-container-arabic' : 'partner-button-container'}`}>
                      {error ? <span className='error-msg'>{error}</span> :
                       loading ? <Loader size={40} /> :
                        message ? <span className='success-msg'>{message}</span> :
                          null
                    }

                    {message ? null : 
                        <button type="submit" className="partner-styled-button"
                        disabled={loading}
                        style={{
                          fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                        }}>
                        {isArabic ? 'ارسال' : 'Submit'}
                      </button>}
                      <p className={`${isArabic ? 'centered-text-arabic' : 'centered-text'} text-[#788E9F] capitalize`}
                        style={{
                          fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                          lineHeight: isArabic ? (isMobile ? '1rem' : '2rem') : isMobile ? '1rem' : '2rem',
                          marginTop: isArabic ? (isMobile ? '0.5rem' : '0.5rem') : isMobile ? '0.5rem' : '0.5rem',
                          marginRight: isArabic ? (isMobile ? '0rem' : '0rem') : isMobile ? '0rem' : '3rem',
                        }}>
                        {isArabic ?
                          <>
                            شريك سابق؟ <a className="text-[#C7E9F5] cursor-pointer" onClick={loginRedirect}>سجل الدخول</a>
                          </> :
                          <>
                            I’m already A partner?&nbsp; <a className='text-[#C7E9F5] cursor-pointer' onClick={loginRedirect}> Login</a>
                          </>
                        }
                      </p>
                    </div>
                  </div>
                </>
                :
                <>
                  <div style={{ visibility: 'hidden' }} className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="city"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'المدينة' : 'City*'}
                    </label>
                    <input type="text" id="city" name='city' value={city} onChange={handleChange} placeholder={isArabic ? 'ادخل اسم المدينة' : '#city'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? ' حساب الانستغرام (اختياري)' : 'Instagram Account(optional)'}
                    </label>
                    <input type="instagram" id="instagram" name="instagram" value={instagram} onChange={handleChange} placeholder={isArabic ? '@معرف الانستاغرام' : '@yourInstagram'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} />
                   <div className={`${isArabic ? 'partner-button-container-arabic' : 'partner-button-container'}`}
                      style={{ width: '81%' }}>
                      {error ? <span className='error-msg'>{error}</span> :
                       loading ? <Loader size={40} /> :
                        message ? <span className='success-msg'>{message}</span> :
                          null
                      }
                      {message ? null :
                        <button type="submit" className="partner-styled-button"
                        disabled={loading}
                          style={{
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                          }}>
                          {isArabic ? 'ارسال' : 'Submit'}
                        </button>}
                      <p className={`${isArabic ? 'centered-text-arabic' : 'centered-text'} text-[#788E9F] capitalize`}
                        style={{
                          fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                          lineHeight: isArabic ? (isMobile ? '1rem' : '2rem') : isMobile ? '1rem' : '2rem',
                          marginTop: isArabic ? (isMobile ? '0.5rem' : '0.5rem') : isMobile ? '0.5rem' : '0.5rem',
                          marginRight: isArabic ? (isMobile ? '0rem' : '') : isMobile ? '0rem' : '3rem',
                          margin: isArabic ? 'auto' : '',
                        }}>
                        {isArabic ?
                          <>
                            شريك سابق؟ <a className="text-[#C7E9F5] cursor-pointer" onClick={loginRedirect}>سجل الدخول</a>
                          </> :
                          <>
                            I’m already A partner?&nbsp; <a className='text-[#C7E9F5] cursor-pointer' onClick={loginRedirect}> Login</a>
                          </>
                        }
                      </p>
                    </div>
                  </div>
                </>
              ) : isMobile ? (
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                        {isArabic ? ' حساب الانستغرام (اختياري)' : 'Instagram Account(optional)'}
                      </label>
                    <input type="instagram" id="instagram" name="instagram" value={instagram} onChange={handleChange} placeholder={isArabic ? '@معرف الانستاغرام' : '@yourInstagram'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} />
                  </div>
                    <div className={`${isArabic ? 'partner-button-container-arabic' : 'partner-button-container'}`}>
                      {error ? <span className='error-msg'>{error}</span> :
                       loading ? <Loader size={40} /> :
                        message ? <span className='success-msg'>{message}</span> :
                          null
                    }
                      {message ? null :
                        <button type="submit" className="partner-styled-button"
                          style={{
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                          }}
                        disabled={loading}>
                          {isArabic ? 'ارسال' : 'Submit'}
                        </button>}
                    <p className={`${isArabic ? 'centered-text-arabic' : 'centered-text'} text-[#788E9F] capitalize`}
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                        lineHeight: isArabic ? (isMobile ? '1rem' : '2rem') : isMobile ? '1rem' : '2rem',
                        marginTop: isArabic ? (isMobile ? '0.5rem' : '0.5rem') : isMobile ? '0.5rem' : '0.5rem',
                        marginRight: isArabic ? (isMobile ? '0rem' : '4rem') : isMobile ? '0rem' : '3rem',
                      }}>
                      {isArabic ?
                        <>
                          شريك سابق؟ <a className="text-[#C7E9F5] cursor-pointer" onClick={loginRedirect}>سجل الدخول</a>
                        </> :
                        <>
                          I’m already A partner?&nbsp; <a className='text-[#C7E9F5] cursor-pointer' onClick={loginRedirect}> Login</a>
                        </>
                      }
                    </p>
                  </div>
                </>
              ) :
                <>
                  <div className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? ' حساب الانستغرام (اختياري)' : 'Instagram Account(optional)'}
                    </label>
                    <input type="instagram" id="instagram" name="instagram" value={instagram} onChange={handleChange} placeholder={isArabic ? '@معرف الانستاغرام' : '@yourInstagram'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} />
                    <div className={`${isArabic ? 'partner-button-container-arabic' : 'partner-button-container'}`}
                      style={{ display: 'flex', flexDirection: 'column', width: '81%' }}>
                        {error ? <span className='error-msg'>{error}</span> :
                       loading ? <Loader size={40} /> :
                        message ? <span className='success-msg'>{message}</span> :
                          null
                        }
                        {message ? null :
                          <button type="submit" className="partner-styled-button"
                            style={{
                              fontFamily: isArabic ? (isMobile ? 'ArabicFontBold' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                            }}
                            disabled={loading}>
                            {isArabic ? 'ارسال' : 'Submit'}
                          </button>}
                      <div className="centered-paragraph-container">
                        <p className={`${isArabic ? 'centered-text-arabic' : 'centered-text'} text-[#788E9F] capitalize`}
                          style={{
                            fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                            lineHeight: isArabic ? (isMobile ? '1rem' : '2rem') : isMobile ? '1rem' : '2rem',
                            marginTop: isArabic ? (isMobile ? '0.5rem' : '0.5rem') : isMobile ? '0.5rem' : '0.5rem',
                            margin: 'auto',
                          }}>
                          {isArabic ?
                            <>
                              شريك سابق؟ <a className="text-[#C7E9F5] cursor-pointer" onClick={loginRedirect}>سجل الدخول</a>
                            </> :
                            <>
                              I’m already A partner?&nbsp; <a className='text-[#C7E9F5] cursor-pointer' onClick={loginRedirect}> Login</a>
                            </>
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ visibility: 'hidden' }} className={`${isArabic ? 'input-group-arabic' : 'input-group'}`}>
                    <label htmlFor="city"
                      style={{
                        fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                      }}>
                      {isArabic ? 'المدينة' : 'City*'}
                    </label>
                    <input type="text" id="city" name="city" value={city} onChange={handleChange}  placeholder={isArabic ? 'ادخل اسم المدينة' : '#city'} className={`${isArabic ? 'styled-input-arabic' : 'styled-input'}`} required />
                  </div>
                </>
              }
            </div>
          </form>
        </div>
        <div className="partner-image"
          style={{
            paddingTop: isArabic ? (isMobile ? '0' : '2.6rem') : isMobile ? '0' : '2.6rem',
          }}>
          <img src={cup} alt="Partner Image" />
          <p className='text-[#D7D7D9]'
            style={{
              fontSize: isArabic ? (isMobile ? '' : '20px') : isMobile ? '' : '20px',
              fontFamily: isArabic ? (isMobile ? 'ArabicFontLight' : 'ArabicFontLight') : isMobile ? 'EngRegular' : 'EngRegular',
              lineHeight: isMobile ? '1.5rem' : '2rem',
            }}>
            {isArabic ? (isMobile ? (
              <>
                إذا كان لديك أي أسئلة او استفسار حول أن تصبح شريك <br />
                <span
                  className="text-[#5D8FB7]"
                  style={{
                    fontFamily: isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium',
                  }}
                >
                  sales@beanz.ae
                </span>
                {' '}   يرجى مراسلتنا على{' '}
              </>
            ) : <>
              إذا كان لديك أي أسئلة او استفسار حول أن تصبح شريك <br />
              <span
                className="text-[#5D8FB7]"
                style={{
                  fontFamily: isMobile ? 'ArabicFontMedium' : 'ArabicFontMedium',
                }}
              >
                sales@beanz.ae
              </span>
              {' '}   يرجى مراسلتنا على{' '}
            </>
            ) : (
              isMobile ? (
                <>
                  If you have any questions about becoming a partner,<br /> please email us at <span className='text-[#5D8FB7]'
                    style={{
                      fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngSemi' : 'EngSemi',
                    }}>sales@beanz.ae</span>.
                </>
              ) : <>
                If you have any questions about becoming <br /> a partner, please email us at <span className='text-[#5D8FB7]'
                  style={{
                    fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngSemi' : 'EngSemi',
                  }}>sales@beanz.ae</span>.
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}
