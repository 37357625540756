import React, { useEffect, useState } from 'react'
import paper from '../assets/1.jpg'
import person from '../assets/2.jpg'
import laptop from '../assets/3.jpg'
import alc from '../assets/Shops/alc.png'
import black from '../assets/Shops/Black Doze.png'
import brew from '../assets/Shops/BREWLAB.png'
import dosa from '../assets/Shops/dosa.png'
import foxy from '../assets/Shops/Foxy.png'
import journey from '../assets/Shops/Journeys.png'
import kave from '../assets/Shops/kave.png'
import pre from '../assets/Shops/Le Pre.png'
import petite from '../assets/Shops/La Petite.png'
import native from '../assets/Shops/Native.png'
import petal from '../assets/Shops/Petal.png'
import street from '../assets/Shops/Street 27.png'
import spin from '../assets/Shops/spin.png'
import ror from '../assets/Shops/ROR.png'
import rain from '../assets/Shops/Rain.png'
import tierra from '../assets/Shops/tierra.png'
import deer from '../assets/Shops/White Deer.png'
import wacup from '../assets/Shops/WACUP.png'
import alqahwa from '../assets/Shops/alqahwa.png'
import koob from '../assets/Shops/Koub.png'
import vue from '../assets/Shops/Vue.png'
import trio from '../assets/Shops/TRIO.png'
import rascomac from '../assets/Shops/rascomac.png'
import notes from '../assets/Shops/Notes.png'
import '../index.css'

interface propTypes {
  isArabic: boolean;
  id: string;
  ref: any;
  sectionTranslations: Record<string, string>;
}

const coffeeShopImages = [alc, black, brew, dosa, foxy, journey, kave, pre, petite, native, petal, street, spin, ror, rain, tierra, deer, wacup, alqahwa, koob, vue, trio, rascomac, notes,
  alc, black, brew, dosa, foxy, journey, kave, pre, petite, native, petal, street, spin, ror, rain, tierra, deer, wacup, alqahwa, koob, vue, trio, rascomac, notes];

export function Coffeeshops({ isArabic, ref, sectionTranslations }: propTypes) {
  const [isMobile, setIsMobile] = useState(false);
  const [duplicatedCoffeeShopImages, setDuplicatedCoffeeShopImages] = useState([...coffeeShopImages]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const numImages = coffeeShopImages.length;
  const imageWidth = isMobile ? 65 : 100;
  const totalWidth = numImages * imageWidth;

  useEffect(() => {

    const interval = setInterval(() => {
      setDuplicatedCoffeeShopImages((prevImages) => {
        const nextImages = [...prevImages, ...coffeeShopImages];
        return nextImages.slice(0, numImages);
      });
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='bg-[#DDF0F680] text-white scroll-margin-top' ref={ref} id={sectionTranslations.coffeeshops} >
      <section className="bg-[#DDF0F680]" >
        <div className="container  py-20 mx-auto">
          <h1 className={`text-center text-[#335A79] capitalize  text-[40px]`}
            style={{
              fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
              fontSize: isArabic ? (isMobile ? '30px' : '45px') : isMobile ? '30px' : '45px',
            }}>
            {isArabic ? 'أصحاب المقاهي' : 'Coffee Shops'}</h1>
          <p className={`max-w-2xl mx-auto my-6 text-center text-[#00121F]  text-[25px]`}
            style={{
              fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontMedium') : isMobile ? 'EngMedium' : 'EngMedium',
              fontSize: isArabic ? (isMobile ? '14px' : '21px') : isMobile ? '14px' : '21px',
              marginTop: isMobile ? '0.5rem' : '',
              marginBottom: isMobile ? '0.1rem' : '2.5rem',
            }}>
            {isArabic ? 'نحو النمو والنجاح' : 'Let’s Grow And Succeed'}
          </p>
          <div className="flex items-center justify-center">
            <div className=" flex items-center overflow-hidden">
              <div className="flex py-12 whitespace-nowrap   "
                style={{
                  paddingTop: isMobile ? '1.5rem' : '1rem',
                  paddingBottom: isMobile ? '0rem' : '3rem',
                }}
              >
                <div className="scroll-container" style={{ width: `${totalWidth}px` }}>
                  {duplicatedCoffeeShopImages.map((image, index) => (
                    <div key={index} className="scroll-item">
                      <img
                        className="w-25 h-25"
                        src={image}
                        alt={`Image ${index + 1}`}
                        style={{
                          width: isMobile ? '65px' : '100px',
                          height: isMobile ? '65px' : '100px',
                          marginLeft: isMobile ? '0.2rem' : '1.25rem',
                          marginRight: isMobile ? '0.2rem' : '1.25rem',
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto scroll-container2">
            <div className={`flex flex-row md:flex-row gap-10 wrapping`}
              style={{
                flexDirection: isArabic ? 'row-reverse' : 'row',
                justifyContent: isArabic ? 'flex-end' : 'flex-start',
                paddingLeft:isArabic ? '' : '1.5rem',
                overflow: 'hidden',
              }}>
              <div className="flex flex-col items-center oval-container">
                <img className="rounded-xl aspect-square oval-image" width={413} height={650} src={paper} alt="Paper" />
                <h1 className={`mt-4 text-2xl text-[20px] text-[#2B1300] capitalize ${isArabic ? (isMobile ? 'coffee_ar_mob' : 'coffee_ar') : isMobile ? 'coffee_eng_mob' : 'coffee_mob'}`}
                  style={{
                    fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                    width: isArabic ? (isMobile ? '240px' : '') : isMobile ? '' : '',
                  }}>
                  {isArabic ? 'تبسيط عملية إدارة الطلبات ' : 'Simplify To-Go Orders'}</h1>
                <p className={` text-[#00121F] text-center w-300px heightt`}
                  style={{
                    fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                    fontSize: isArabic ? (isMobile ? '12px' : '17px') : isMobile ? '12px' : '17px',
                    width: isMobile ? '165px' : '85%',
                    lineHeight: isMobile ? '21px' : '30px',
                    marginTop: isMobile ? '0.2rem' : '0.75rem',
                  }}>
                  {isArabic ? ' يقوم النظام بأتمتة الطلبات وتبسيطها باستخدام جهاز سهل الاستخدام' : 'Our innovative order management system automates and simplifies orders with an easy-to-use device.'}</p>
              </div>
              <div className="flex flex-col items-center oval-container">
                <img className="rounded-xl aspect-square oval-image" width={413} height={650} src={person} alt="Person" />
                <h1 className={`mt-4 text-2xl text-[20px] text-[#2B1300] capitalize ${isArabic ? (isMobile ? 'coffee_ar_mob' : 'coffee_ar') : isMobile ? 'coffee_eng_mob' : 'coffee_mob'}`}
                  style={{
                    fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                  }}>
                  {isArabic ? ' خمس دقائق أسرع لكل عميل' : ' Five Minutes Faster'}</h1>
                <p className={`mt-3 text-[#00121F] text-center w-300px heightt`}
                  style={{
                    fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                    fontSize: isArabic ? (isMobile ? '12px' : '17px') : isMobile ? '12px' : '17px',
                    width: isMobile ? '165px' : '86%',
                    lineHeight: isMobile ? '21px' : '30px',
                    marginTop: isMobile ? '0.2rem' : '0.75rem',
                  }}>
                  {isArabic ? ' لم يعد المقهى الخاص بك مضطرًا لإضاعة الوقت في تلقي الطلبات من سيارة العميل' : 'No longer does your coffee shop have to waste time taking orders from customer car.'}</p>
              </div>
              <div className="flex flex-col items-center oval-container center-container">
                <img className="rounded-xl aspect-square oval-image" width={413} height={650} src={laptop} alt="Laptop" />
                <h1 className={`mt-4 text-2xl text-[20px] text-[#2B1300] capitalize ${isArabic ? (isMobile ? 'coffee_ar_mob' : 'coffee_ar') : isMobile ? 'coffee_eng_mob' : 'coffee_mob'}`}
                  style={{
                    fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontBold') : isMobile ? 'EngBold' : 'EngBold',
                  }}>
                  {isArabic ? 'قم بتحليل بيانات محلك ' : 'Improve with Data'}</h1>
                <p className={`mt-3 text-[#00121F] text-center w-300px heightt`}
                  style={{
                    fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontRegular') : isMobile ? 'EngRegular' : 'EngRegular',
                    fontSize: isArabic ? (isMobile ? '12px' : '17px') : isMobile ? '12px' : '17px',
                    width: isMobile ? '145px' : '79%',
                    lineHeight: isMobile ? '21px' : '30px',
                    marginTop: isMobile ? '0.2rem' : '0.75rem',
                  }}>
                  {isArabic ? ' مع بيانات فائقة يمكنك بشكل أفضل فهم عملائك وزيادة المبيعات' : ' With superior data you can better understand your customers and increase sales.'}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
